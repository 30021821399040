
<script setup lang="ts">
    import { ref, computed, watch, onMounted, onBeforeUnmount, Ref } from "vue";
    import { type DataObject } from "o365-dataobject";
    import OSignature from "./Signatures.Signature.vue";

    export interface Props {
        dataObject: DataObject;
        idPath?: string;
    };

    const container = ref<HTMLElement>();

    const props = defineProps<Props>();

    const signatures = computed(() => props.dataObject.data.filter(e => e.ShallSign && !e.Closed).sort((a, b) => (a.Sequence ?? Number.MAX_VALUE) - (b.Sequence ?? Number.MAX_VALUE)));
    window._signatures = signatures;

    const colClass = ref<string>("col-12 col-md-6 col-lg-4");

    const emits = defineEmits(["signed"]);

    function getColClass(): string {
        const width: number = container.value?.clientWidth ?? 0;
        if (width < 576) {
            return "col-12";
        } else if (width < 992) {
            return "col-6";
        } else if (width < 1400) {
            return "col-4";
        } else {
            return "col-3";
        }
    }

    function updateSize() {
        colClass.value = getColClass();
    }

    watch(container, () => {
        if (container.value) {
            const observer = new ResizeObserver(updateSize);
            observer.observe(container.value);
        }
    });
</script>

<template>
    <div ref="container">
        <div class="row g-2">
            <template v-for="row in signatures">
                <div :class="colClass">
                    <OSignature :dataObject="dataObject" :data="signatures" :row="row" :idPath="idPath" @signed="emits('signed')" />
                </div>
            </template>
            <!-- <div> -->
                <!-- <button class="w-100 btn btn-danger" @click="dataObject.data.forEach(row => row.Signed = null); dataObject.save().then(() => dataObject.load());"> -->
                    <!-- Unsign all -->
                <!-- </button> -->
            <!-- </div> -->
        </div>
    </div>
</template>
